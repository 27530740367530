<template>
  <NuxtLink :key="voice.id" :to="postUrl(voice.link)" class="inline-flex">
    <UCard class="flex group m-2 ml-4 mr-0 relative overflow-hidden hover:bg-white/50">
      <div class="flex flex-col gap-4 w-60">
        <header>
          <AuthorVoiceWidget :id="voice.author" :small="true" />
        </header>
        <div v-html="voice.content?.rendered || ''"  class="line-clamp-4 text-sm"/>
        <footer class="flex mt-auto">
          <time :datetime="voice.date" class="flex-1 text-gray-400 text-xs group-hover:underline">{{ useRelativeDate(voice.date) }}</time>
          <div class="flex gap-4 text-gray-400 text-xs">
            <div v-if="voice.reactions.total > 0">
              <UIcon name="i-heroicons-hand-thumb-up" class="w-4 h-4 align-top" />
              <span class="inline-block pl-1">{{ voice.reactions.total }}</span>
            </div>
            <div v-if="voice.comment_count > 0">
              <UIcon name="i-heroicons-chat-bubble-left" class="w-4 h-4 align-top" />
              <span class="inline-block pl-1">{{ voice.comment_count }}</span>
            </div>
          </div>
        </footer>
      </div>
    </UCard>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { LVN_API_Post } from '~/types/interfaces';

const props = defineProps<{
  voice: LVN_API_Post
}>()

const postUrl = (link: string) => {
  const url = new URL(link)
  return url.pathname
}
</script>